import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { id: "nav" };
const _hoisted_2 = { class: "menu content" };
const _hoisted_3 = ["src"];
const _hoisted_4 = { class: "mobile-menu content" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_router_link = _resolveComponent("router-link");
    return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_router_link, {
                to: "/",
                id: "homepage",
                class: "primary-font"
            }, {
                default: _withCtx(() => [
                    _createElementVNode("img", {
                        src: require('@/assets/logo.png')
                    }, null, 8, _hoisted_3),
                    _cache[0] || (_cache[0] = _createElementVNode("b", { class: "primary-font" }, "MINECRIME", -1))
                ]),
                _: 1
            }),
            _createVNode(_component_router_link, { to: "/products" }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('menu.services')), 1)
                ]),
                _: 1
            }),
            _createVNode(_component_router_link, { to: "/search" }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('menu.search')), 1)
                ]),
                _: 1
            }),
            _createVNode(_component_router_link, { to: "/user" }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(this.$store.getters.isLogged ? _ctx.$t('menu.profile') : _ctx.$t('menu.login')), 1)
                ]),
                _: 1
            })
        ]),
        _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_router_link, { to: "/" }, {
                default: _withCtx(() => [
                    _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fi fi-rs-home inactive" }, null, -1)),
                    _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fi fi-ss-home active" }, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('menu.home')), 1)
                ]),
                _: 1
            }),
            _createVNode(_component_router_link, { to: "/search" }, {
                default: _withCtx(() => [
                    _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fi fi-rs-search inactive" }, null, -1)),
                    _cache[4] || (_cache[4] = _createElementVNode("i", { class: "fi fi-bs-search active" }, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('menu.search')), 1)
                ]),
                _: 1
            }),
            _createVNode(_component_router_link, { to: "/user" }, {
                default: _withCtx(() => [
                    _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fi fi-rs-user inactive" }, null, -1)),
                    _cache[6] || (_cache[6] = _createElementVNode("i", { class: "fi fi-ss-user active" }, null, -1)),
                    _createTextVNode(" " + _toDisplayString(this.$store.getters.isLogged ? _ctx.$t('menu.profile') : _ctx.$t('menu.login')), 1)
                ]),
                _: 1
            })
        ])
    ]));
}
